@import './src/style/vars';

.wrapper {
  padding: 100px 0;
  background-color: $color-amber;

  @include for-size(lg) {
    padding: 60px 0;
  }

  @include for-size(sm) {
    padding: 50px 0;
  }

  .header {
    margin-bottom: 60px;

    @include for-size(lg) {
      margin-bottom: 40px;
    }

    @include for-size(sm) {
      margin-bottom: 30px;
    }
  }

  .headerTitle {
    display: flex;
    column-gap: 20px;
    align-items: center;

    .imgWrapper {
      @include for-size(lg) {
        display: none;
      }
    }
  }

  .collapse {
    margin-bottom: 60px;
    @include font-h2;
    color: #3c3c3c;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    border-top: 2px solid #3c3c3c;
    border-bottom: 2px solid #3c3c3c;

    @include for-size(md) {
      margin-bottom: 30px;
      font-size: 13px;
    }

    & > * {
      border: none !important;
    }
  }

  .collapse__content {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-transform: none;

    @include for-size(md) {
      font-size: 12px;
      line-height: 20px;
    }

    li {
      margin-left: 30px;

      @include for-size(md) {
        padding: 0;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    margin-bottom: 60px;

    @include for-size(xl) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;
    }

    @include for-size(lg) {
      margin-bottom: 40px;
    }

    @include for-size(md) {
      margin-bottom: 30px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
      color: #3c3c3c;

      .iconWrapper {
        width: 103px;
        height: 103px;
        background-color: $color-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-size(md) {
          width: 64px;
          height: 64px;

          img {
            width: 32px;
          }
        }
      }

      .title {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.3;
        text-transform: uppercase;
        text-align: center;

        @include for-size(sm) {
          font-size: 13px;
        }
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;

        @include for-size(sm) {
          font-size: 8px;
        }
      }
    }
  }

  .screenshots {
    position: relative;
    width: 100%;
    height: 416px;

    @include for-size(xl) {
      display: none;
    }

    .imgWrapper {
      position: absolute;

      &:first-child {
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        z-index: 2;
      }

      &:nth-child(2) {
        right: -100px;
        z-index: 1;

        @include for-size(xxl) {
          right: 0;
        }
      }

      &:nth-child(3) {
        left: -100px;
        z-index: 1;

        @include for-size(xxl) {
          left: 0;
        }
      }
    }
  }

  .carouselWrapper {
    display: none;

    @include for-size(xl) {
      display: flex;
      justify-content: center;
      column-gap: 15px;
      align-items: center;
    }

    @include for-size(sm) {
      column-gap: 5px;
    }

    .carousel {
      width: 510px;

      @include for-size(md) {
        width: 280px;
      }

      .card {
        margin: 0 auto 40px auto;
        width: 500px;
        height: 286px;
        background-color: inherit;
        display: flex !important;
        justify-content: center;
        align-items: center;

        @include for-size(md) {
          width: 280px;
          height: 160px;

          img {
            width: 280px;
          }
        }
      }

      button {
        background-color: $color-white !important;
      }
    }

    .arrowCarousel {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: inherit;
      border-radius: 50%;
      border: 1px solid $color-black;
      cursor: pointer;

      @include for-size(md) {
        width: 26px;
        height: 26px;

        img {
          width: 6px;
        }
      }

      @media screen and (max-width: 390px) {
        display: none;
      }
    }
  }
}
