@import './src/style/vars';

.wrapper {
  padding: 36px 0;
  height: 320px;
  background-color: $color-primary;

  @include for-size(xl) {
    height: auto;
  }

  .info {
    padding: 0 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    color: $color-white;

    @include for-size(xl) {
      width: 100%;
      padding: 0 20px;
    }

    @include for-size(md) {
      padding: 0 10px;
    }

    @include for-size(sm) {
      padding: 0;
      font-size: 13px;
    }

    p {
      margin-top: 0;
      text-align: justify;

      .respect {
        margin-top: 10px;
        display: block;
        text-align: right;

        @include for-size(xl) {
          text-align: left;
        }

        @include for-size(lg) {
          margin-top: 20px;
        }

        .regular {
          font-weight: 400;

          @include for-size(sm) {
            display: none;
          }
        }
      }

      img {
        display: none;

        @include for-size(xl) {
          display: block;
          width: 400px;
          float: right;
          shape-outside: url('./img/leader-small.png');
          position: relative;
          left: 40px;
          bottom: -36px;
          shape-image-threshold: 0.5;
        }

        @include for-size(lg) {
          width: 300px;
          margin-top: 20px;
        }

        @include for-size(md) {
          width: 300px;
          margin-top: 30px;
          left: 28px;
        }

        @media screen and (max-width: 670px) {
          bottom: -48px;
        }

        @media screen and (max-width: 601px) {
          margin-top: 34px;
          bottom: -44px;
        }

        @include for-size(sm) {
          margin-top: 36px;
          bottom: -36px;
          left: 18px;
        }

        @media screen and (max-width: 430px) {
          margin-top: 60px;
          bottom: -36px;
        }

        @media screen and (max-width: 390px) {
          width: 220px;
          margin-top: 100px;
          bottom: -36px;
        }
      }
    }
  }

  .imgWrapper {
    position: relative;

    .img {
      position: absolute;
      bottom: -64px;
      right: 100px;
      margin: 0;

      @include for-size(xl) {
        display: none;
      }

      img {
        width: 520px;

        @include for-size(xl) {
          float: left;
          margin-right: 10px;
        }
      }
    }
  }
}
