@import './src/style/vars';

.wrapper {
  padding: 80px 0 50px 0;

  @include for-size(sm) {
    padding: 0 0 50px 0;
  }

  .header {
    margin-bottom: 40px;

    @include for-size(sm) {
      margin-bottom: 20px;
    }
  }

  .projects {
    height: 660px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @include for-size(xl) {
      max-width: 900px;
    }

    @include for-size(lg) {
      max-width: 650px;
      height: 450px;
      margin-bottom: 0;
    }

    @include for-size(sm) {
      width: 100%;
      max-width: 350px;
      height: 190px;
      margin-bottom: 30px;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &Center {
        justify-content: flex-start;
        align-items: center;
        row-gap: 50px;

        @include for-size(lg) {
          row-gap: 35px;
        }

        @include for-size(sm) {
          row-gap: 20px;
        }
      }

      .card {
        width: 240px;
        height: 120px;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 5px;
        border-radius: 10px;
        color: $color-white;
        background-color: $color-primary;
        @include font-bold;

        @include for-size(lg) {
          width: 160px;
          height: 80px;
          justify-content: flex-end;
          row-gap: 2px;
          padding: 3px 0;

          img {
            width: 34px;
          }
        }

        @include for-size(sm) {
          width: 84px;
          height: 40px;
          font-size: 7px;
          border-radius: 4px;

          img {
            width: 16px;
          }
        }
      }

      .mainIconWrapper {
        flex-shrink: 0;
        position: relative;
        width: 365px;
        height: 365px;
        background-color: $color-secondary;
        border-radius: 50%;
        display: flex;
        flex-direction: column-reverse;
        z-index: 2;

        @include for-size(lg) {
          width: 200px;
          height: 200px;
        }

        @include for-size(sm) {
          width: 128px;
          height: 128px;
        }

        .mainImg {
          width: 365px;
          position: absolute;
          top: 25px;
          right: -5px;

          @include for-size(lg) {
            width: 204px;
            top: 15px;
            right: -3px;
          }

          @include for-size(sm) {
            width: 130px;
            top: 11px;
            right: -1px;
          }
        }

        .content {
          padding-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 15px;

          @include for-size(lg) {
            padding-bottom: 15px;
            row-gap: 5px;
          }

          @include for-size(sm) {
            padding-bottom: 10px;
            row-gap: 0;
          }

          .logoIcon {
            @include for-size(lg) {
              width: 120px;
            }

            @include for-size(sm) {
              width: 88px;
            }
          }

          .info {
            font-size: 19px;
            font-weight: 600;
            line-height: 1.3;
            text-transform: uppercase;
            color: $color-white;

            @include for-size(lg) {
              font-size: 10px;
            }

            @include for-size(sm) {
              font-size: 7px;
            }
          }
        }
      }
    }
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
}
