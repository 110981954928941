@import './src/style/vars';

.wrapper {
  padding: 60px 0;
  background-color: $color-white;

  @include for-size(md) {
    padding: 40px 0;
  }

  .contacts {
    color: $color-black;
    margin-bottom: 60px;

    @include for-size(sm) {
      margin-bottom: 20px;
    }

    .title {
      padding-bottom: 20px;
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -0.08;
      line-height: 1.1;
      border-bottom: 2px solid $color-black;

      @include for-size(sm) {
        font-size: 36px;
      }
    }

    .contact {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      @include for-size(sm) {
        margin-bottom: 20px;
      }

      &__title {
        @include font-h2;
        text-transform: uppercase;

        @include for-size(sm) {
          font-size: 20px;
        }

        a {
          &:hover {
            color: $color-black;
          }
          &:active {
            color: $color-black;
          }
          &:visited {
            color: $color-black;
          }
        }
      }

      &__phone {
        display: inline;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.3;

        a {
          &:hover {
            color: $color-black;
          }
          &:active {
            color: $color-black;
          }
          &:visited {
            color: $color-black;
          }
        }
      }
    }
  }

  .telegramChanel {
    padding-top: 30px;
    border-top: 2px solid $color-black;

    @include for-size(md) {
      padding-top: 20px;
    }

    @include for-size(sm) {
      padding-top: 10px;
    }

    .text {
      margin: 0 auto 30px auto;
      width: 100%;
      max-width: 940px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
      color: $color-black;
      text-align: center;

      @include for-size(lg) {
        margin-bottom: 20px;
      }

      @include for-size(md) {
        font-size: 16px;
      }

      @include for-size(sm) {
        margin-bottom: 10px;
        font-size: 14px;
      }

      a {
        text-decoration: underline;

        &:hover {
          color: $color-black;
        }
        &:active {
          color: $color-black;
        }
        &:visited {
          color: $color-black;
        }
      }
    }

    .imgWrapper {
      margin: 0 auto;
      width: 316px;
      height: 316px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid $color-black;
      border-radius: 10px;

      @include for-size(md) {
        width: 240px;
        height: 240px;

        img {
          width: 200px;
        }
      }

      @include for-size(sm) {
        width: 190px;
        height: 190px;

        img {
          width: 160px;
        }
      }
    }
  }
}
