.layout {
  font-family: 'Montserrat', sans-serif;

  .content {
  }

  .footer {
    color: #fff;
    background-color: #4096ff;
  }
}
