.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  button {
    cursor: pointer;

    &:before,
    &:after {
      background: #000;
    }
  }
}
