@import 'mixin';

/* Colors */
$color-primary: #688ac5;
$color-secondary: #ae92cc;
$color-white: #fff;
$color-black: #3c3c3c;
$color-light-grey: #e9eff7;
$color-light-grey-2: #f7f9fc;
$color-light-grey-3: #d0dbed;
$color-blue-grey: #95add6;
$color-dark-blue: #365893;
$color-lavender: #e7d7f8;
$color-violet-blue: #282a62;
$color-violet: #7c5a9e;
$color-amber: #e9cba0;

$box-shadow-header: 0px 5px 5px -5px rgba(34, 60, 80, 0.6);
