/* Breakpoints */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

/* Mixin принимает значения размера и возвращает @media */
@mixin for-size($size) {
  @if $size == sm {
    @media screen and (max-width: $breakpoint-sm) {
      @content;
    }
  }
  @if $size == md {
    @media screen and (max-width: $breakpoint-md) {
      @content;
    }
  }
  @if $size == lg {
    @media screen and (max-width: $breakpoint-lg) {
      @content;
    }
  }
  @if $size == xl {
    @media screen and (max-width: $breakpoint-xl) {
      @content;
    }
  }
  @if $size == xxl {
    @media screen and (max-width: $breakpoint-xxl) {
      @content;
    }
  }
}

/* Mixin для свойств текста, названия взяты из макета дизайнера */
@mixin font-h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.3;
}

@mixin font-h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
}

@mixin font-h3 {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}

@mixin font-bold {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.4;
}

/* Mixin разные в целях избежания повторений кода */
@mixin position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
