@import './src/style/vars';

.wrapper {
  padding: 50px 0 30px 0;
  background-image: url('assets/img/bg-main.jpg');
  background-size: cover;
  background-position: 25% 75%;

  .steps {
    margin-bottom: 80px;

    @include for-size(sm) {
      margin-bottom: 30px;
    }

    .step {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .stepTitle {
        display: flex;
        column-gap: 25px;
        align-items: center;

        @include for-size(md) {
          column-gap: 15px;
          align-items: start;
        }

        @include for-size(sm) {
          column-gap: 10px;
        }

        .circle {
          flex-shrink: 0;
          width: 103px;
          height: 103px;
          background-color: $color-secondary;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 75px;
          color: $color-light-grey-2;
          border-radius: 50%;

          @include for-size(md) {
            width: 46px;
            height: 46px;
            font-size: 32px;
          }
        }
      }
    }
  }

  .workingPrinciple {
    width: 100%;
    display: flex;
    justify-content: center;

    .iconWrapper {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;

      @include for-size(lg) {
        width: 120px;
      }

      @include for-size(md) {
        width: 100px;

        img {
          width: 60px;
        }
      }

      @include for-size(sm) {
        width: 90px;

        img {
          width: 40px;
        }
      }

      .iconTitle {
        color: $color-primary;
        text-align: center;
        @include font-bold;

        @include for-size(lg) {
          font-size: 12px;
        }
      }
    }
  }

  .proposals {
    padding-left: 130px;
    list-style: none;

    @include for-size(md) {
      padding-left: 0;
    }

    li {
      padding-left: 38px;
      margin-bottom: 10px;
      color: $color-primary;
      background: url('assets/icons/tick.svg') no-repeat 0 0;
      @include font-h3;

      @include for-size(md) {
        font-size: 13px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cards {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    @include for-size(lg) {
      display: none;
    }
  }

  .card {
    position: relative;
    width: 280px;
    height: 206px;
    background-image: url('assets/img/bg-card.png');
    display: flex;

    @include for-size(sm) {
      width: 250px;
      height: 185px;
      background-image: url('assets/img/bg-card-small.png');
    }

    .cardIcon {
      position: absolute;
      top: -25px;
      right: 93px;
      width: 96px;
      height: 96px;
      background-color: $color-secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @include for-size(sm) {
        width: 85px;
        height: 85px;
        top: -21px;
        right: 84px;
      }
    }

    span {
      padding: 83px 15px 15px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include font-bold;
      color: $color-white;
    }
  }

  .cardWrapper {
    padding: 30px 0 40px 0;
    display: flex !important;
    justify-content: center;
    column-gap: 5px;
  }

  .carouselWrapper {
    display: none;

    @include for-size(lg) {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      align-items: center;
    }

    @include for-size(sm) {
      column-gap: 5px;
    }
  }

  .carousel {
    width: 300px;

    @include for-size(sm) {
      width: 250px;
    }
  }

  .arrowCarousel {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0dbed;
    border-radius: 50%;

    @media screen and (max-width: 390px) {
      display: none;
    }
  }
}
