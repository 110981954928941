.wrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
