@import './src/style/vars';

.wrapper {
  width: 100%;
  color: $color-primary;

  .title {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.08rem;
    text-transform: uppercase;

    @include for-size(md) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  .subtitle {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.3;

    @include for-size(md) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .divider {
    margin-bottom: 20px;
    height: 2px;
    width: 100%;
    background-color: $color-primary;

    @include for-size(md) {
      margin-bottom: 10px;
    }
  }
}
