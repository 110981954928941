@import './src/style/vars';

.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/img/bunner.jpg');
  background-size: cover;
  background-position: center;

  .content {
    margin: 0 auto;
    width: 100%;
    max-width: 750px;
    display: flex;
    column-gap: 50px;

    @include for-size(lg) {
      max-width: 500px;
      flex-direction: column;
      align-items: center;
      column-gap: 0;
      row-gap: 20px;
    }

    @include for-size(sm) {
      row-gap: 10px;
    }

    .logo {
      @include for-size(lg) {
        display: none;
      }
    }

    .logoFull {
      display: none;
      @include for-size(lg) {
        display: block;
      }
    }

    .block {
      padding-top: 40px;

      @include for-size(lg) {
        padding-top: 0;
        padding-left: 80px;
      }

      @include for-size(sm) {
        margin: 0 auto;
        padding-left: 0;
      }

      .title {
        margin-bottom: 20px;

        @include for-size(lg) {
          display: none;
        }
      }

      .subtitle {
        width: 100%;
        color: $color-primary;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.4;

        @include for-size(sm) {
          max-width: 305px;
          font-size: 13px;
        }

        .text {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }
  }
}
