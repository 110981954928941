@import './src/style/vars';

.wrapper {
  padding: 50px 0;

  @include for-size(sm) {
    padding: 0 0 50px 0;
  }

  @include for-size(sm) {
    padding: 50px 0;
  }

  .header {
    margin-bottom: 60px;

    @include for-size(lg) {
      margin-bottom: 40px;
    }

    @include for-size(sm) {
      margin-bottom: 20px;
    }
  }

  .cards {
    margin: 0 auto;
    width: 100%;
    max-width: 1044px;
    height: 1042px;
    display: flex;
    flex-direction: column;
    row-gap: 94px;

    @include for-size(lg) {
      height: auto;
      align-items: center;
      row-gap: 45px;
    }

    .cardsRow {
      display: flex;
      justify-content: space-between;
    }

    .cardBox {
      padding: 24px;
      background-color: $color-primary;
      border-radius: 10px;

      @include for-size(lg) {
        width: 100%;
        max-width: 350px;
      }

      .cardBoxTitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
      }

      .cardBoxLogo {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }

      .icons {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .iconsRow {
          display: flex;
          justify-content: center;
          column-gap: 20px;

          &:nth-child(2) {
            justify-content: space-between;
            column-gap: 0;
          }

          &:nth-child(3) {
            justify-content: space-between;
            column-gap: 0;
          }

          .iconWrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            row-gap: 4px;

            .iconTitle {
              font-size: 13px;
              font-weight: 600;
              line-height: 1.4;
              color: $color-white;
              text-align: center;
            }
          }
        }

        .mainIcon {
          @include position-absolute-center;

          .circle {
            position: relative;
            width: 93px;
            height: 93px;
            background-color: $color-blue-grey;
            border-radius: 50%;
          }

          .imgWrapper {
            @include position-absolute-center;
          }
        }
      }
    }

    .cardCircle {
      width: 275px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      @include for-size(lg) {
        display: none;
      }

      .circle {
        position: relative;
        width: 275px;
        height: 275px;
        background-color: $color-primary;
        border-radius: 50%;

        img {
          @include position-absolute-center;
        }
      }

      .info {
        @include font-bold;
        text-align: center;
        color: $color-dark-blue;
      }
    }

    .cardCircleBig {
      margin: 0 auto;
      width: 372px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      @include for-size(lg) {
        display: none;
      }

      .circle {
        position: relative;
        width: 372px;
        height: 372px;
        background-color: $color-primary;
        border-radius: 50%;

        img {
          @include position-absolute-center;
        }
      }

      .info {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        text-align: center;
        color: $color-dark-blue;

        span {
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }

    .carouselWrapper {
      display: none;

      @include for-size(lg) {
        display: flex;
        justify-content: center;
        column-gap: 15px;
        align-items: center;
      }

      @include for-size(sm) {
        column-gap: 5px;
      }
    }

    .carousel {
      width: 100%;
      max-width: 275px;

      .cardWrapper {
        padding-bottom: 30px;
        display: flex !important;
        flex-direction: column;
        row-gap: 4px;

        .card {
          margin: 0 auto;
          flex-shrink: 0;
          position: relative;
          width: 240px;
          height: 240px;
          border-radius: 50%;
          background-color: $color-primary;

          img {
            @include position-absolute-center;
          }
        }

        .text {
          font-size: 17px;
          font-weight: 600;
          line-height: 1.4;
          color: $color-dark-blue;
          text-align: center;
        }
      }
    }

    .arrowCarousel {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-light-grey-3;
      border-radius: 50%;

      @media screen and (max-width: 390px) {
        display: none;
      }
    }
  }
}
