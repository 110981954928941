@import './src/style/vars';

.wrapper {
  padding: 100px 0;

  @include for-size(lg) {
    padding: 60px 0;
  }

  @include for-size(sm) {
    padding: 50px 0;
  }

  .header {
    margin-bottom: 60px;

    @include for-size(lg) {
      margin-bottom: 40px;
    }

    @include for-size(sm) {
      margin-bottom: 30px;
    }
  }

  .icons {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 876px;
    height: auto;

    .iconRow {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;

      &:first-child {
        justify-content: center;
      }

      &:nth-child(2) {
        margin-top: -80px;
        margin-bottom: 40px;
        padding: 0 100px;

        @include for-size(md) {
          margin-top: -70px;
          padding: 0 90px;
        }

        @include for-size(sm) {
          margin-top: -30px;
          margin-bottom: 0;
          padding: 0 20px;
          justify-content: space-around;
        }

        @media screen and (max-width: 390px) {
          padding: 0 5px;
          column-gap: 10px;
        }
      }

      &:nth-child(3) {
        margin-bottom: 90px;

        @include for-size(lg) {
          margin-bottom: 60px;
        }

        @include for-size(md) {
          margin-bottom: 45px;
        }

        @include for-size(sm) {
          margin-bottom: 15px;
        }
      }

      &:nth-child(4) {
        margin-bottom: 15px;
        padding: 0 50px;

        @include for-size(md) {
          padding: 0 25px;
        }

        @include for-size(sm) {
          margin-bottom: 0;
        }

        @media screen and (max-width: 390px) {
          padding: 0;
          column-gap: 10px;
        }
      }

      &:nth-child(5) {
        padding: 0 240px;

        @include for-size(md) {
          padding: 0 160px;
        }

        @include for-size(sm) {
          padding: 0 40px;
          justify-content: space-around;
        }

        @media screen and (max-width: 390px) {
          padding: 0;
          column-gap: 10px;
          justify-content: center;
        }
      }

      .iconWrapper {
        width: 100%;
        max-width: 228px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;

        @include for-size(lg) {
          max-width: 200px;
        }

        @include for-size(md) {
          max-width: 120px;
        }

        @include for-size(sm) {
          max-width: 80px;
          row-gap: 5px;
        }

        .circle {
          width: 127px;
          height: 127px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-primary;
          border-radius: 50%;

          @include for-size(lg) {
            width: 100px;
            height: 100px;

            img {
              width: 55px;
            }
          }

          @include for-size(md) {
            width: 80px;
            height: 80px;

            img {
              width: 45px;
            }
          }

          @include for-size(sm) {
            width: 50px;
            height: 50px;

            img {
              width: 30px;
            }
          }
        }

        .title {
          color: $color-black;
          font-size: 17px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          text-transform: uppercase;

          @include for-size(lg) {
            font-size: 14px;
          }

          @include for-size(md) {
            font-size: 10px;
          }

          @include for-size(sm) {
            font-size: 8px;
          }
        }
      }
    }

    .blockCenter {
      .statistic {
        width: 100%;
        height: 90px;
        max-width: 320px;
        padding: 5px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-violet;
        color: $color-white;
        border-radius: 10px;
        @include position-absolute-center;
        z-index: 2;

        @include for-size(md) {
          height: 60px;
          max-width: 220px;
        }

        @include for-size(sm) {
          height: 44px;
          max-width: 160px;
          padding: 4px 10px;
        }

        @media screen and (max-width: 390px) {
          max-width: 130px;
          padding: 4px;
        }

        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 1.3;

          @include for-size(md) {
            font-size: 20px;
          }

          @include for-size(sm) {
            font-size: 14px;
          }
        }

        .subtitle {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.3;
          text-transform: uppercase;
          text-align: center;

          @include for-size(md) {
            font-size: 10px;
          }

          @include for-size(sm) {
            font-size: 8px;
          }
        }
      }

      .imgWrapper {
        position: absolute;
        top: 238px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        @include for-size(lg) {
          top: 160px;

          img {
            width: 210px;
          }
        }

        @include for-size(md) {
          top: 128px;

          img {
            width: 180px;
          }
        }

        @include for-size(sm) {
          top: 88px;

          img {
            width: 110px;
          }
        }
      }
    }
  }
}
