@import './src/style/vars';

.wrapper {
  padding: 100px 0;
  background-color: $color-violet;

  @include for-size(lg) {
    padding: 60px 0;
  }

  @include for-size(sm) {
    padding: 50px 0;
  }

  .header {
    margin-bottom: 30px;

    @include for-size(sm) {
      margin-bottom: 20px;
    }
  }

  .list {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    @include for-size(sm) {
      margin-bottom: 50px;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 20px 0;
      column-gap: 30px;
      border-bottom: 2px solid $color-white;
      color: $color-white;

      @include for-size(lg) {
        column-gap: 20px;
      }

      @include for-size(md) {
        border: none;
        align-items: flex-start;
      }

      @include for-size(sm) {
        padding: 10px 0;
      }

      .iconWrapper {
        flex-shrink: 0;
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-size(md) {
          width: 60px;

          img {
            width: 50px;
          }
        }
      }

      .infoBlock {
        display: flex;
        align-items: center;
        column-gap: 124px;

        @include for-size(xl) {
          column-gap: 40px;
        }

        @include for-size(lg) {
          column-gap: 20px;
        }

        @include for-size(md) {
          flex-direction: column;
          align-items: flex-start;
          row-gap: 20px;
        }

        @include for-size(md) {
          row-gap: 10px;
        }

        .title {
          @include font-h2;
          width: 320px;
          flex-shrink: 0;
          text-transform: uppercase;

          @include for-size(lg) {
            width: 280px;
          }

          @include for-size(md) {
            width: 100%;
            font-size: 20px;
          }

          @include for-size(sm) {
            font-size: 17px;
          }
        }

        .info {
          @include font-h3;
          width: 100%;

          @include for-size(md) {
            font-size: 14px;
          }

          @include for-size(sm) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .portfolio {
    color: $color-white;
    text-transform: uppercase;

    .title {
      margin-bottom: 40px;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.3;

      @include for-size(md) {
        margin-bottom: 20px;
      }
    }

    .cards {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      @include for-size(lg) {
        row-gap: 20px;
        margin-bottom: 30px;
      }

      @include for-size(sm) {
        row-gap: 10px;
        margin-bottom: 20px;
      }

      .cardRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 40px;

        @include for-size(lg) {
          column-gap: 20px;
        }

        @include for-size(sm) {
          column-gap: 10px;
        }

        .card {
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 10px;

          &__title {
            padding: 10px;
            flex-shrink: 0;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.3;

            @include for-size(lg) {
              padding: 8px;
              font-size: 20px;
            }

            @include for-size(md) {
              padding: 6px;
              font-size: 14px;
            }

            @include for-size(sm) {
              padding: 4px;
              font-size: 10px;
            }
          }
        }
      }
    }

    .cardVideo {
      width: 100%;
      .videoResponsive {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        border-radius: 10px;

        & iframe,
        & object,
        & embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .cardTitle {
        padding: 10px;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.3;

        @include for-size(lg) {
          padding: 8px;
          font-size: 20px;
        }

        @include for-size(md) {
          padding: 6px;
          font-size: 14px;
        }

        @include for-size(sm) {
          padding: 4px;
          font-size: 10px;
        }
      }
    }
  }
}
