@import './src/style/vars';

.wrapper {
  padding-bottom: 100px;

  @include for-size(lg) {
    padding-bottom: 60px;
  }

  @include for-size(sm) {
    padding-bottom: 50px;
  }

  .header {
    margin-bottom: 40px;
  }

  .table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;

    @include for-size(md) {
      margin: 0 0 0 -20px;
      width: calc(100% + 40px);
    }

    tr {
      &:nth-child(even) {
        background-color: $color-lavender;

        &:hover {
          background-color: $color-lavender;

          td {
            background-color: $color-lavender !important;
          }
        }
      }

      &:nth-child(odd) {
        background-color: $color-white;

        &:hover {
          background-color: $color-white;

          td {
            background-color: $color-white !important;
          }
        }
      }

    }
  }

  .column {
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;

    .columnTitle {
      @include font-bold;
      color: $color-violet-blue;
      text-align: center;
    }

    .columnSubtitle {
      position: relative;
      margin-right: 10px;
      height: 40px;
      background-color: $color-violet;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $color-white;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }

      &:before {
        content: '';
        position: absolute;
        right: -10px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $color-violet;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-violet-blue;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }

  .iconWrapper {
    margin: 0 auto;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;

    span {
      color: $color-violet-blue;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    color: $color-violet-blue;
    @include font-h3;

    @include for-size(md) {
      display: none;
    }
  }
}
