@import 'vars';
@import 'mixin';
@import 'reset';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

html,
body {
  height: 100%;
  font-weight: 400;
  scroll-behavior: smooth;
}
