@import './src/style/vars';

.wrapper {
  padding: 30px 0;
  background-color: $color-primary;

  .content {
    display: flex;
    column-gap: 10px;

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
      color: $color-white;

      @include for-size(sm) {
        font-size: 13px;
      }
    }
  }
}
