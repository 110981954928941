@import 'src/style/vars';

.wrapper {
  padding: 80px 0 25px 0;
  background-color: $color-light-grey;

  @include for-size(xl) {
    padding-top: 25px;
  }

  .header {
    margin-bottom: 20px;
  }

  .infoText {
    margin-bottom: 50px;
  }

  .blockCards {
    margin: 0 auto 50px auto;
    width: 100%;
    max-width: 880px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    text-align: center;

    .cards {
      display: flex;
      justify-content: space-around;

      .card {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;

        @include for-size(md) {
          width: 100px;
        }

        .cardIcon {
          height: 103px;
          width: 103px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $color-secondary;

          @include for-size(md) {
            height: 80px;
            width: 80px;

            img {
              width: 40px;
            }
          }

          @include for-size(sm) {
            height: 60px;
            width: 60px;

            img {
              width: 32px;
            }
          }
        }

        .cardTitle {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          text-transform: uppercase;
          color: $color-primary;

          @include for-size(md) {
            font-size: 14px;
          }

          @include for-size(sm) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .blockInfo {
    width: 100%;

    .info {
      padding: 0 30px;
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      text-align: justify;

      @include for-size(xl) {
        width: 100%;
        padding: 0 20px;
      }

      @include for-size(md) {
        text-align: left;
        padding: 0 10px;
      }

      @include for-size(sm) {
        padding: 0;
      }

      p:nth-child(2) {
        margin: 0 -30px;
        padding: 20px 30px;
        color: $color-white;
        background-color: $color-secondary;
        border-radius: 10px;

        @include for-size(xl) {
          margin: 0 -20px;
          padding: 20px 20px;
        }

        @include for-size(sm) {
          border-radius: 0;
        }
      }

      .button {
        display: flex;
        justify-content: center;
      }
    }

    .imgWrapper {
      position: relative;

      @include for-size(xl) {
        display: none;
      }

      .img {
        position: absolute;
        bottom: 75px;
        right: 10px;
        margin: 0;
      }
    }
  }

  .text {
    font-size: 17px;
    line-height: 1.4;
    color: $color-primary;

    @include for-size(sm) {
      font-size: 13px;
    }

    a {
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
